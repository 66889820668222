import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';
import { apiGetAsaChatsByUrgency, apiMarkChatAsReadByDoctor, AsaChat, AsaChatsFilter } from '../api/asaChat';
import Header from '../components/Header'; // Import the updated Header component

const UrgentChatsList: React.FC = () => {
  const { user } = useContext(AuthContext) as AuthContextType;
  const [chats, setChats] = useState<AsaChat[]>([]);
  const [filter, setFilter] = useState<AsaChatsFilter>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedChats, setExpandedChats] = useState<{ [key: string]: boolean }>({});

  const fetchChats = async () => {
    setLoading(true);
    try {
      const response = await apiGetAsaChatsByUrgency(filter);
      setChats(response.data.chats);
    } catch (err) {
      setError('Failed to fetch chats');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChats();
  }, [filter]);

  const handleMarkAsRead = async (chatId: string) => {
    try {
      const result = await apiMarkChatAsReadByDoctor(chatId);
      if (result.data.success) {
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatId ? { ...chat, readByDoctor: true } : chat
          )
        );
      }
    } catch (err) {
      console.error('Failed to mark chat as read:', err);
    }
  };

  const getUrgencyTagColor = (urgency: number) => {
    switch (urgency) {
      case 5:
        return 'bg-red-600 text-white';
      case 4:
        return 'bg-red-400 text-white';
      case 3:
        return 'bg-yellow-400 text-white';
      case 2:
        return 'bg-green-300 text-gray-800';
      case 1:
        return 'bg-green-200 text-gray-800';
      default:
        return 'bg-gray-300 text-gray-800';
    }
  };

  const toggleExpanded = (chatId: string) => {
    setExpandedChats((prevState) => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  const truncateText = (text: string, length: number) => {
    if (text.length > length) {
      return text.slice(0, length) + '...';
    }
    return text;
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 text-white">
      <Header /> {/* The Header now includes the LogoutBar */}
      <div className="w-full max-w-3xl p-6">
        <div className="mt-6 space-y-4">
          {loading && <div className="text-center text-blue-400">Loading...</div>}
          {error && <div className="text-center text-red-400">{error}</div>}

          {chats.length > 0 ? (
            chats.map((chat) => (
              <div
                key={chat.id}
                className={`p-4 rounded-lg shadow-md backdrop-filter backdrop-blur-lg bg-opacity-20 bg-white transition-transform transform hover:scale-105 ${
                  chat.readByDoctor ? 'opacity-70' : 'opacity-100'
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center ${getUrgencyTagColor(chat.urgency)}`}>
                    <span className="font-bold">{chat.urgency}</span>
                  </div>

                  <button
                    onClick={() => handleMarkAsRead(chat.id)}
                    className={`${
                      chat.readByDoctor ? 'invisible' : 'visible'
                    } text-green-500 hover:text-green-600`}
                    aria-label={`Mark chat from ${chat.user.fullname} as read`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  </button>
                </div>

                <div className="mt-4">
                  <p className="font-semibold text-lg">{chat.user.fullname}</p>
                  <p className="text-sm text-gray-300">{chat.user.email}</p>
                  <p className="text-sm text-gray-300">Kennitala: {chat.user.ssn}</p>
                  <p className="text-xs text-gray-500 mt-2">
                    Created: {getTimeString(chat.createdAt)}
                  </p>
                  <p className="text-xs text-gray-500">Updated: {getTimeString(chat.updatedAt)}</p>
                </div>

                <div className="mt-4">
                  <p className="text-gray-300">
                    Diagnosis:{' '}
                    {expandedChats[chat.id]
                      ? chat.diagnosis
                      : truncateText(chat.diagnosis, 60)}
                  </p>
                  <button
                    onClick={() => toggleExpanded(chat.id)}
                    className="text-blue-400 hover:underline text-sm mt-2"
                  >
                    {expandedChats[chat.id] ? 'Show Less' : 'Show More'}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-400">No chats found.</p>
          )}
        </div>
      </div>
    </div>
  );
};


const getTimeString = (timestampSeconds: number) => {
  const date = new Date(timestampSeconds * 1000);
  const dateDay = new Date(date)
  const today = new Date();

  dateDay.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (dateDay.getTime() === today.getTime()) {
    return formatTime(date) + ' í dag';
  } else if (dateDay.getTime() === yesterday.getTime()) {
    return formatTime(date) + ' í gær';
  } else {
    return date.toLocaleString('en-GB', {day: "numeric", month: "long", year: "numeric"});
  }
}

function formatTime(date: Date) {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export default UrgentChatsList;
