import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { apiDoctorsList } from '../api/doctor';
import { useNavigate } from 'react-router-dom';

// Replace with your actual logo path
const logoSrc = '/testlogo.png';

interface Doctor {
  email: string;
  role: string;
  fullname: string;
}

const DoctorList: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await apiDoctorsList();
        setDoctors(res.data.doctors);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert('Error loading doctors');
      }
    };

    if (auth?.isAdmin) {
      fetchData();
    }
  }, [auth]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-blue-50 p-6 flex flex-col">

      {/* Header with logo, title, and logout */}
      <div className="grid grid-cols-3 items-center max-w-5xl mx-auto p-3 w-full">

        {/* Logo on the far left */}
        <div className="justify-self-start">
          <img src={logoSrc} alt="Logo" className="h-12 w-12 rounded-full shadow-md" />
        </div>

        {/* Title centered */}
        <div className="justify-self-center">
          <h2 className="text-3xl font-semibold text-gray-800">Læknar</h2>
        </div>

        {/* Logout Button on the far right */}
        <div className="justify-self-end">
          <button
            onClick={context?.logout ?? (() => { })}
            className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>

          </button>
        </div>

      </div>

      {/* Main Content (Doctor List) */}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-6">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader size={50} color={"#3b82f6"} />
          </div>
        ) : doctors.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {doctors.map((doctor, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-md p-4 flex flex-col justify-between transform transition-transform hover:scale-105"
              >
                <div>
                  <h3 className="text-xl font-bold text-gray-800">{doctor.fullname}</h3>
                  <p className="text-gray-500">{doctor.email}</p>
                  <p className="text-blue-600 font-semibold">{doctor.role}</p>
                </div>
                <button
                  onClick={() => navigate(`/doctor/${doctor.email}`)}
                  className="mt-4 bg-blue-100 text-blue-600 px-3 py-2 rounded-full hover:bg-blue-200 transition-all text-sm"
                >
                  Skoða
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No doctors found.</p>
        )}
      </div>

      {/* Larger Floating Action Button with Tooltip */}
      <div className="fixed bottom-6 right-6 flex items-center space-x-2 group">
        <button
          onClick={() => navigate('/create-doctor')}
          className="bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-full h-24 w-24 flex items-center justify-center shadow-xl hover:from-pink-600 hover:to-purple-600 transition-all transform hover:scale-110 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
        <span className="opacity-0 group-hover:opacity-100 bg-white text-gray-800 text-sm font-semibold py-1 px-3 rounded-lg shadow-md transition-opacity duration-300">
          Skapa lækni
        </span>
      </div>
    </div>
  );
};

export default DoctorList;
